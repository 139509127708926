import {
  createStore
} from "vuex";
import moduleA from './A'
import moduleB from './B'
import getUrl from './getUrl'
export default createStore({
  modules: {
    moduleA,
    moduleB,
    getUrl
  }, //模块化
});