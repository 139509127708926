<template>
    <div>
        <h3>共 {{ data.mv.length }} 条</h3>
        <div class="cearch_BOX">
            <div class="box" v-for="(item, index) in data.mv" :key="index">
                <img @click="player(item.id, item.fims)" :src="item.image" />
                <span>{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { reactive, onMounted, ref, nextTick, watch } from 'vue';
import { getMovLike, getTvLike } from "../../net/api";
const route = new useRoute()
let startmv = ref(1)
let endmv = ref(10)
let startv = ref(1)
let endtv = ref(10)
let data = reactive({
    mv: [],
})
const router = new useRouter()
function player(id, de) {
    router.push({ path: '/player', query: { id, de } })
    document.documentElement.style.overflow = 'auto'
}
async function getMovLikefun(name, startmv, endmv) {
    await getMovLike(name, startmv, endmv).then((res) => {
        res.data.forEach(function (item) {
            data.mv.push({ "id": item.id, "name": item.mvname, "image": item.mvimage, "fims": "mv" });
        })
    });
}
async function getTvLikefun(name, startv, endtv) {
    await getTvLike(name, startv, endtv).then((res) => {
        res.data.forEach(function (item) {
            data.mv.push({ "id": item.id, "name": item.tvname, "image": item.tvimage, "fims": "tv" });
        })
    });
}
watch(() => route.query.name, (news, old) => {
    nextTick(() => {
        if (news) {
            if (data.mv.length > 0) data.mv = []
            getMovLikefun(news, startmv.value, endmv.value)
            getTvLikefun(news, startv.value, endtv.value)
        }
    })
})
onMounted(() => {
    if (data.mv.length > 0) data.mvs
    if (window.history && window.history.pushState) {
   window.onpopstate = function(event) {
    document.getElementsByClassName("player_box_pl")[0].remove()
   // document.getElementsByClassName("player_box_pl")[0].style.display = "none"
    document.getElementsByClassName("nulls")[0].style.display = "block"
   };
}
    nextTick(() => {
        if (route.query.name) {
            getMovLikefun(route.query.name, startmv.value, endmv.value)
            getTvLikefun(route.query.name, startv.value, endtv.value)
        }
    })
})
</script>

<style lang="scss" scoped>
h3 {
    text-align: center;
}

.cearch_BOX {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.cearch_BOX div {
    padding: 10px 10px 10px 10px;
}

.cearch_BOX img {
    height: 200px;
    width: 150px;
}

.cearch_BOX span {
    display: block;
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 auto;
}
</style>