<template>
    <div class="player_box">
        <div class="playbox">
            <div class="menu_x">
                {{ sonname }}
            </div>
            <div class="pxo">
                <div class="top">
                    <div class="top_sonname"></div>
                    <div></div>
                </div>
                <div class="play_mid">
                    <div><svg t="1676813721009" class="icon" viewBox="0 0 1131 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="11704" width="32" height="32">
                            <path
                                d="M563.539716 117.531905C580.277991 101.744834 595.521517 85.278332 612.813043 71.36455 798.608379-78.095773 1077.650806 24.842919 1120.493801 259.431128 1141.284398 373.264986 1106.594578 470.486749 1023.247773 550.494635 1019.573991 554.022825 1015.827412 557.478218 1012.236133 561.08891 864.149232 709.850389 716.062332 858.611867 568.057934 1007.455848 560.016379 1015.541081 551.222597 1021.277422 539.419905 1020.986237 524.967431 1020.631962 514.242123 1013.934711 508.112682 1000.991545 501.842502 987.742635 503.764322 975.017858 512.912379 963.52091 514.56728 961.438938 516.542483 959.61418 518.425479 957.721479 667.162692 808.256303 815.953289 658.834806 964.583735 509.262863 979.769024 493.985365 995.725953 479.04273 1008.441024 461.814294 1116.130882 315.891867 1031.337858 105.365232 852.55037 75.746882 760.836853 60.551886 683.677725 89.709194 622.485232 159.666351 607.848341 176.399773 591.16345 187.906427 568.69854 189.105137 543.598408 190.439735 523.734749 180.09782 507.894294 160.821384 482.488417 129.902408 451.220019 106.651299 414.346313 91.038938 268.307412 29.210692 101.152758 121.365839 76.61073 278.013573 63.686976 360.491678 87.835905 432.006673 146.106844 491.587943 188.178199 534.605649 231.127962 576.759507 273.573005 619.408379 316.537327 662.576531 359.394882 705.85145 402.233024 749.145782 416.408872 763.472076 417.952152 782.389384 406.426085 797.17672 393.448948 813.822787 369.528114 815.283564 353.838104 799.928417 337.886028 784.316057 322.365877 768.262066 306.651602 752.407052 242.421081 687.60872 178.506009 622.499791 113.79018 558.191621 91.863962 536.406142 70.379374 514.543014 53.446976 488.525649-68.239166 301.546161 40.824114 46.322654 260.227033 7.410654 379.753555-13.787602 480.120114 23.673327 560.676398 114.37255 561.778047 115.610085 562.908815 116.837915 563.539716 117.531905L563.539716 117.531905Z"
                                fill="#ffffff" p-id="11705"></path>
                        </svg></div>
                    <div><svg t="1676815066642" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="12817" width="32" height="32">
                            <path
                                d="M170.71 724.37c-17.38 0-31.82-14.21-31.82-31.59 0-17.61 14.44-31.82 31.82-31.82h82.37c43.32 0 93.86-56.19 145.99-122.99l9.24 12.2c10.4 13.31 20.31 26.62 30.25 39.7-58.67 73.57-118.7 134.5-185.47 134.5h-82.38z m708.76-379.55l-52.36 52.36v0.21l-52.34 52.36c-7.46 7.23-19.41 7.23-27.08 0-4.07-4.07-5.87-9.7-5.43-14.88V363.1h-77.84c-43.32 0-93.63 56.19-145.99 122.97l-9.03-12.17c-10.37-13.31-20.31-26.62-30.69-39.72 59.13-73.55 119.14-134.71 185.7-134.71h77.84v-72.88c0-10.37 8.6-19.18 18.97-19.18 5.18 0 10.37 2.48 13.54 5.64l52.34 52.57 52.36 52.13c7.44 7.67 7.44 19.64 0.46 27.08h-0.45v-0.01z m-40.16-13.54c-19.87-19.41-39.49-39.26-59.13-58.9v117.79c19.64-19.63 39.26-39.04 59.13-58.89z m40.16 374.81h0.46c6.98-7.44 6.98-19.64-0.46-26.85l-52.36-52.35-52.34-52.36c-3.17-3.6-8.36-5.85-13.54-5.85-10.37 0-18.97 8.57-18.97 18.95v73.34h-77.84c-53.03 0-116.64-84.18-180.27-168.12-73.57-96.56-146.68-193.38-231.06-193.38h-82.38c-17.38 0-31.82 14.44-31.82 31.82 0 17.61 14.44 31.82 31.82 31.82h82.37c53.03 0 116.87 84.18 180.51 168.1 73.34 96.58 146.68 193.17 230.83 193.17h77.84v71.74c-0.44 5.43 1.36 10.84 5.43 14.9 7.67 7.44 19.62 7.44 27.08 0l52.34-52.59 52.36-52.34z m-40.16-13.31c-19.87 19.39-39.49 39.03-59.13 59.11V633.65l20.08 20.31 39.05 38.82z"
                                fill="#ffffff" p-id="12818"></path>
                        </svg></div>
                    <!-- <div @click="lists"><svg t="1676815113290" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                                                    xmlns="http://www.w3.org/2000/svg" p-id="13911" width="32" height="32">
                                                                    <path
                                                                        d="M170.666667 213.333333h682.666666v85.333334H170.666667V213.333333z m0 512h426.666666v85.333334H170.666667v-85.333334z m0-256h682.666666v85.333334H170.666667v-85.333334z"
                                                                        fill="#ffffff" p-id="13912"></path>
                                                                </svg></div> -->
                    <div @click="volumes"><svg v-if="volume" t="1676815141072" class="icon" viewBox="0 0 1024 1024"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="15019" width="32" height="32">
                            <path
                                d="M128 420.576v200.864h149.12l175.456 140.064V284.288l-169.792 136.288H128z m132.256-64l204.288-163.968a32 32 0 0 1 52.032 24.96v610.432a32 32 0 0 1-51.968 24.992l-209.92-167.552H96a32 32 0 0 1-32-32v-264.864a32 32 0 0 1 32-32h164.256zM670.784 720.128a32 32 0 0 1-44.832-45.664 214.08 214.08 0 0 0 64.32-153.312 213.92 213.92 0 0 0-55.776-144.448 32 32 0 1 1 47.36-43.04 277.92 277.92 0 0 1 72.416 187.488 278.08 278.08 0 0 1-83.488 198.976zM822.912 858.88a32 32 0 1 1-45.888-44.608A419.008 419.008 0 0 0 896 521.152c0-108.704-41.376-210.848-114.432-288.384a32 32 0 0 1 46.592-43.872c84.16 89.28 131.84 207.04 131.84 332.256 0 127.84-49.76 247.904-137.088 337.728z"
                                fill="#ffffff" p-id="15020"></path>
                        </svg>
                        <svg v-else t="1676819710196" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="5011" width="32" height="32">
                            <path
                                d="M512 261.168v501.68L295.984 590.032 278.448 576H192v-128H278.448l17.536-14.032L512 261.168M576 128L256 384h-64a64 64 0 0 0-64 64v128a64 64 0 0 0 64 64h64l320 256V128zM818.736 512l67.888-67.888a32 32 0 1 0-45.248-45.248l-67.888 67.888-67.888-67.888a32 32 0 1 0-45.248 45.248L728.24 512l-67.888 67.888a32 32 0 1 0 45.248 45.248l67.888-67.888 67.888 67.888c6.256 6.256 14.432 9.376 22.624 9.376s16.368-3.12 22.624-9.376a32 32 0 0 0 0-45.248L818.736 512z"
                                fill="#ffffff" p-id="5012"></path>
                        </svg>
                    </div>
                </div>
                <div class="play">
                    <div class="start">{{ timestart }}</div>
                    <div>
                        <div class="bar" @click="bar_">
                            <div class="bar_">
                            </div>
                            <div class="bar_b"></div>
                        </div>
                    </div>
                    <div>
                        <div v-if="timeend === 'NaN:NaN'">00:00</div>
                        <div v-else>{{ timeend }}</div>
                    </div>
                </div>
                <div class="but">
                    <div @click="sonupper"><svg t="1676815930447" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="8174" width="32" height="32">
                            <path
                                d="M128 102.4V928c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32V102.4c0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32zM774.028 143.795l-404.352 331.09c-27.383 22.42-27.435 58.886 0 81.35l404.352 331.09c27.382 22.42 49.676 12.003 49.676-23.366V167.16c0-35.646-22.24-45.83-49.676-23.365z m-40.546-49.518c69.12-56.597 154.222-16.897 154.222 72.883v696.8c0 89.481-85.14 129.447-154.222 72.883L329.13 605.753c-58.7-48.064-58.662-132.353 0-180.386l404.352-331.09z"
                                fill="#ffffff" p-id="8175"></path>
                        </svg></div>
                    <div @click="leftPlay">
                        <svg v-if="!play" t="1676812874568" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="2753" width="32" height="32">
                            <path
                                d="M298.666667 426.666667a42.666667 42.666667 0 0 1 42.666666 42.666666v85.333334a42.666667 42.666667 0 0 1-85.333333 0v-85.333334a42.666667 42.666667 0 0 1 42.666667-42.666666z m0 213.333333a42.666667 42.666667 0 0 1 42.666666 42.666667v29.525333a8.533333 8.533333 0 0 0 13.44 6.954667l285.952-200.149334a8.533333 8.533333 0 0 0 0-13.994666L354.773333 304.853333A8.533333 8.533333 0 0 0 341.333333 311.808V341.333333a42.666667 42.666667 0 1 1-85.333333 0V213.333333a42.666667 42.666667 0 0 1 42.752-42.666666 42.666667 42.666667 0 0 1 24.746667 8.106666l425.728 298.026667c4.352 2.986667 8.106667 6.741333 11.093333 11.093333 5.034667 7.381333 7.68 15.786667 7.68 24.106667 0 13.610667-6.698667 26.965333-18.773333 35.2L323.456 845.226667c-7.253333 5.162667-15.914667 8.106667-24.789333 8.106666a42.666667 42.666667 0 0 1-42.666667-42.666666v-128a42.666667 42.666667 0 0 1 42.666667-42.666667z"
                                fill="#ffffff" p-id="2754"></path>
                        </svg>
                        <svg v-else t="1676813397760" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="10568" width="32" height="32">
                            <path
                                d="M409.6 238.92992h-68.27008c-37.5296 0-68.25984 30.74048-68.25984 68.27008v409.6c0 37.5296 30.73024 68.27008 68.25984 68.27008H409.6c37.5296 0 68.27008-30.74048 68.27008-68.27008V307.2c0-37.5296-30.74048-68.27008-68.27008-68.27008zM409.6 716.8h-68.27008V307.2H409.6v409.6zM682.67008 238.92992H614.4c-37.5296 0-68.27008 30.74048-68.27008 68.27008v409.6c0 37.5296 30.74048 68.27008 68.27008 68.27008h68.27008c37.5296 0 68.25984-30.74048 68.25984-68.27008V307.2c0-37.5296-30.73024-68.27008-68.25984-68.27008z m0 477.87008H614.4V307.2h68.27008v409.6z"
                                p-id="10569" fill="#ffffff"></path>
                        </svg>
                    </div>
                    <div @click="sonnext"><svg t="1676815977428" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="9089" width="32" height="32">
                            <path
                                d="M889.6 921.6V96c0-17.673-14.327-32-32-32-17.673 0-32 14.327-32 32v825.6c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32zM243.572 880.205l404.352-331.09c27.383-22.42 27.435-58.886 0-81.35l-404.352-331.09c-27.382-22.42-49.676-12.003-49.676 23.366V856.84c0 35.646 22.24 45.83 49.676 23.365z m40.546 49.518c-69.12 56.597-154.222 16.897-154.222-72.883v-696.8c0-89.481 85.14-129.447 154.222-72.883l404.352 331.09c58.7 48.064 58.662 132.353 0 180.386l-404.352 331.09z"
                                fill="#ffffff" p-id="9090"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div>
                <div class="boo">
                    <div class="boo_"></div>
                </div>
            </div>
        </div>
        <audio @canplay="poi" autoplay="true"></audio>
    </div>
</template>

<script setup>
import anime from 'animejs';
import { nextTick, onMounted, ref, reactive } from 'vue';
let bar = ref(0)
let play = ref(0)
let audio = null;
let value = ref(0);
let timer = null;
let volume = ref(true)
let timestart = ref("00:00")
let timeend = ref("00:00")
let sonname = ref("")
let id = ref(1)
let sonch = ref(false)
let focus = null
let son = reactive({
    sons: [{
        id:1,
        sonname: "See You Again",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://www.xzmp3.com/down/0853c08a2993.mp3"
    }, {
        id:2,
        sonname: "Free Loop",
        sonimage: "http://img.5nd.com/86/photo/2023album/20231/3278053.jpg",
        sonurl: "https://www.xzmp3.com/down/bc357be765c9.mp3"
    }, {
        id:3,
        sonname: "Stay With Me",
        sonimage: "https://star.kuwo.cn/star/starheads/180/61/54/3278324391.jpg",
        sonurl: "https://m10.music.126.net/20241023232140/d35616a97f7f5b179700982c9b7473f1/ymusic/obj/w5zDlMODwrDDiGjCn8Ky/3959031008/d59e/220e/e4c3/d2aba201d1f048c29d4358da03df23fa.mp3"
    },{
        id:5,
        sonname: "Lemon",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m10.music.126.net/20241023232503/c6e5910cb5072012cb4c86aa45de3f28/ymusic/4d52/ac97/c81f/9c98523f6c9c10d5fe6b2750712b0326.mp3"
    },{
        id:8,
        sonname: "A Thousand Years",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m801.music.126.net/20241023232640/d1622464cb42a942a9caa577ac8f46b3/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/13909855075/ad3e/6c9d/67e2/62905f0728c4ae5cdb20b419b1216124.mp3"
    },{
        id:9,
        sonname: "突然的自我(Live)",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m801.music.126.net/20241023232738/9ec9108dc11c1a8b0ebd956f8ae9aaab/jdymusic/obj/w5zDlMODwrDDiGjCn8Ky/2434865301/f90c/0230/8c5a/a344f74aa4848d3239de700cc76cffa2.mp3"
    },{
        id:10,
        sonname: "干干阙歌(Live)",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m801.music.126.net/20241023232847/0b5172a9e368fe4bb486f85c5defc8c8/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/34373972457/8c7c/2846/50df/7c1c1f2404ca74c09c3384b83a552174.mp3"
    },{
        id:11,
        sonname: "真的爱你",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m801.music.126.net/20241023232937/ef44907d8bba1dfd9e855b8b31f2f8ab/jdymusic/obj/w5zDlMODwrDDiGjCn8Ky/2102448189/2a04/6be4/6414/0f17eac50a9d50f9ecfadfc597a4b668.mp3"
    },{
        id:12,
        sonname: "光辉岁月",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m701.music.126.net/20241023233036/3b6a742cc914797556689586b3014edb/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/29307772629/bfcc/6626/23a3/cad5c065b9c7aad148fcb3af4ab4e50c.mp3"
    },{
        id:13,
        sonname: "一生何求",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m10.music.126.net/20241023233126/45029feb6ce2f1be7ca41e35c362770f/ymusic/c7bd/eb24/4734/1ba92d64e1e0af7dc8fcf56c60ed5b1e.mp3"
    },{
        id:14,
        sonname: "晚秋",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m10.music.126.net/20241023233214/68dc1caf97208f4b59749eaaa60a2684/ymusic/0f0c/010f/0558/8f3c0b3df26e3a8b8c8122cc518e6e08.mp3"
    },{
        id:15,
        sonname: "只想一生跟你走",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m701.music.126.net/20241023233308/5bfed4fd5f54c8d6caab1f12948d05a1/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/32368351798/1623/9906/97ee/73f93b7457b7fa923644e51cac8bb65a.mp3"
    },{
        id:16,
        sonname: "笨小孩",
        sonimage: "http://img.5nd.com/86/photo/2023album/20232/3278091.jpg",
        sonurl: "https://m801.music.126.net/20241023234800/acf885983191aa4e7c19519f92bb8849/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/34569517390/9add/9b03/c750/ede383b91d7b1d3d0fdd6f4dc9fd09c5.mp3"
    }]
})
//let progressX = ref(0)
//let s = ref(false)
function fmtTime(time) {
    time *= 1000;
    //使用毫秒构建日期对象
    time = new Date(time);
    var m = time.getMinutes();
    var s = time.getSeconds();
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    return m + ':' + s;
}

function bar_(e) {
    //if(timer!=null) clearInterval(timer)
    let rate = 0;
    //if (e) {
    rate = e.offsetX / 300;
    bar.value = e.offsetX;
    // }
    // if (s.value) {
    //     bar.value = progressX.value
    //     console.log(bar.value)
    // }
    anime({
        targets: '.play .bar .bar_',
        width: bar.value,
    });
    anime({
        targets: '.play .bar  .bar_b',
        translateX: bar.value,
    });
    if (!isNaN(audio.duration)) {
        audio.currentTime = audio.duration * rate;
        pot()
    } else {
        leftPlay()
        setTimeout(() => {
            audio.currentTime = audio.duration * rate;
            pot()
        }, 1000)
    }
}
function leftPlay() {
    console.log(son.sons.length, son.sons)
    if (play.value) {
        audio.pause()
        play.value = false;
        clearInterval(timer)
        if (focus !== null) {
            focus.pause()
        }
        return false
    }
    if (!audio.src) {
        sonname.value = son.sons[0].sonname
        id.value = son.sons[0].id
        audio.src = son.sons[0].sonurl
        document.getElementsByClassName("boo_")[0].style.backgroundImage = "url(" + son.sons[0].sonimage + ")"
    }
    //console.log(JSON.parse(JSON.stringify(son)))
    if (sonch.value) {
        clearInterval(timer)
    }
    audio.play();
    pot()

}
function sonupper() {
    for (let i = 0; i < son.sons.length; i++) {
        if (id.value === son.sons[i].id) {
            if (i == 0 && !sonch.value) {
                sonname.value = son.sons[0].sonname
                id.value = son.sons[0].id
                audio.src = son.sons[0].sonurl
                document.getElementsByClassName("boo_")[0].style.backgroundImage = "url(" + son.sons[0].sonimage + ")"
                play.value = false
                sonch.value = true
                leftPlay()
            } else {
                if (son.sons[i - 1].sonname) {
                    sonname.value = son.sons[i - 1].sonname
                    id.value = son.sons[ i - 1 ].id
                    audio.src = son.sons[i - 1].sonurl
                    document.getElementsByClassName("boo_")[0].style.backgroundImage = "url(" + son.sons[i - 1].sonimage + ")"
                    play.value = false
                    sonch.value = true
                    leftPlay()
                }
            }

        }
    }

}
function sonnext() {
    for (let i = 0; i <= son.sons.length; i++) {
        if (id.value === son.sons[i].id) {
            if (i == son.sons.length - 1 && !sonch.value) {
                sonname.value = son.sons[0].sonname
                id.value = son.sons[0].id
                audio.src = son.sons[0].sonurl
                document.getElementsByClassName("boo_")[0].style.backgroundImage = "url(" + son.sons[0].sonimage + ")"
                play.value = false
                sonch.value = true
                leftPlay()
                break
            } else {
               // console.log(music_(son.sons[i + 1]))
                if (son.sons[i + 1].sonname) {
                    sonname.value = son.sons[i + 1].sonname
                    id.value = son.sons[ i + 1 ].id
                    audio.src = son.sons[i + 1].sonurl
                    document.getElementsByClassName("boo_")[0].style.backgroundImage = "url(" + son.sons[i + 1].sonimage + ")"
                    play.value = false
                    sonch.value = true
                    leftPlay()
                    break
                }
            }

        }
    }
}
function music_(url){
   return "data:audio/mp3;base64," +
          btoa(
            new Uint8Array(url).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
}
function pot() {
    focus.play()
    sonch.value = false;
    play.value = true;
    timer = setInterval(() => {
        timestart.value = fmtTime(audio.currentTime)
        timeend.value = fmtTime(audio.duration)
        value.value = Math.round((Math.floor(audio.currentTime) / Math.floor(audio.duration)) * 100, 0);
        anime({
            targets: '.play .bar .bar_',
            width: value.value * 3
        });
        anime({
            targets: '.play .bar .bar_b',
            translateX: value.value * 3
        });
    }, 1000)
}
function poi() {
    //初始化
}
function volumes() {
    volume.value = !volume.value
    if (volume.value) { audio.volume = '1'; return false; }
    audio.volume = '0';
}
onMounted(() => {
    nextTick(() => {
        audio = document.querySelector("audio");
        focus = anime({
            targets: '.boo_',
            rotate: {
                value: 360,
            },
            loop: true,
            easing: 'easeInOutQuad',
            duration: 5000,
        })
        audio.addEventListener('pause', () => {
            if (play.value) {
                sonnext()
            }
        }, false)
        //let bar_b = document.getElementsByClassName('bar_b')[0];
        //console.log(bar_b)
        // bar_b.onmousedown = function (event) {
        //     var srcObj = event.target
        //     var rect = srcObj.getBoundingClientRect();
        //     var clientX = event.clientX;
        //     let x = clientX - rect.left;
        //     document.onmousemove = function (event) {
        //         progressX.value = event.clientX - clientX;
        //         if (progressX.value <= 0) {
        //             progressX.value = 0;
        //         }
        //         else if (progressX.value >= 300) {
        //             progressX.value = 300;
        //         }
        //         bar_b.style.left = progressX.value + "px";
        //         s.value = false;
        //     }
        //     document.onmouseup = function (event) {
        //         event = event || window.event;
        //         // 取消鼠标移动事件
        //         document.onmousemove = null;
        //         // 取消鼠标抬起事件
        //         document.onmouseup = null;
        //         s.value = true;
        //         bar_()
        //     };
        // }
    })
})
</script>

<style lang="scss" scoped>
.player_box {
    background-image: url('@/assets/image/music.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top;
    border: 0;
    border-radius: 5px;

}

.playbox {
    width: 620px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(121, 57, 81, 0.7);
    color: white;
    border-radius: 5px;
    overflow: hidden;
   // left: 0;
   // border: 0;
}

.menu_x {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    writing-mode: tb-rl;
    width: 30px;
}

.pxo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 60%;

}

.pxo .top {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
}

.top_sonname {
    color: black;
    padding: 0px 15px 15px 15px;
}

.pxo .play_mid {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
}

.play {
    display: flex;
    justify-content: center;
    align-items: center;
}

.play .start {
    padding-right: 10px;
    padding-left: 10px;
}

.play .ends {
    padding-left: 10px;
}

.bar {
    height: 6px;
    width: 300px;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 999999999;
    position: relative;
}

.bar_ {
    height: 3px;
    background-color: white;
    width: 0px;
    position: relative;
}

.bar_b {
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: white;
    border-radius: 10px;
    left: -2px;
}

.but {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
}

.boo {
    height: 140px;
    width: 140px;
    background-color: #793951;
    border-radius: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin: 15px 15px 15px 15px;
}

.boo_ {
    height: 100px;
    width: 100px;
    border-radius: 100px;
    background-color: white;
}

audio {
    visibility: hidden;

}</style>