<template>
    <div class="filmBox">
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                    <div class="filmBox_">
                        <div class="x">
                            <div class="x_">
                                <div class="x_name">#{{$route.query.type}}电影{{ maxLength[0] }}</div>
                                <div>
                                    <p>一部电影 一段人生</p>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div class="mosts">
            <div v-for="(item, index) in MovMvInfoLike.data" :key="index">
                <img :src="item.mvimage" @error="" alt="" @click="player(item, 'mv')">
                <span>{{ item.mvname }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted,reactive,ref} from "vue"
import { useRoute } from 'vue-router';
import {getMovMvInfoLike} from "@/net/api"
import { max } from "lodash";
export default {
    setup() {
        const maxLength = ref(0);
        let start = ref(1)
        let end = ref(36)
        let timer = ref("")
        const Route = useRoute()
        let MovMvInfoLike = reactive({data:[]})
       async function getMovMvInfoLikefun(){
            await getMovMvInfoLike(Route.query.type,start.value,end.value).then(res=>{
                maxLength.value = res.data.length;
                if (MovMvInfoLike.data.length !== 0) {
                    res.data.data.forEach(function (item) {
                        MovMvInfoLike.data.push(item);
                    })
                } else { MovMvInfoLike.data = res.data.data;}
            })
        }
        function player(id, tv) {

        }
        function scrollFn() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (timer.value) {
        clearInterval(timer.value);
      }
         timer.value = setTimeout(() => {
        if (scrollTop + windowHeight >= scrollHeight - 200) {
          //触底
          start.value = start.value + 36; end.value = end.value + 36;
          if (end.value <= maxLength.value[0])
          getMovMvInfoLikefun()
        } else if (scrollTop + windowHeight <= scrollHeight - 200) {
         
        }
      }, 500);
    }
        onMounted(() => {
            window.addEventListener("scroll", scrollFn);
            getMovMvInfoLikefun()
        })
        return { player,MovMvInfoLike,maxLength }
    }
}


</script>

<style lang="less" scoped>
.filmBox_ {
    display: flex;
    width: 100%;
    height: auto;
}

.x {
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    margin: 0 auto;
}

.x_ {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    padding-top: 20px;
    padding-left: 10px;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;

}

.x_name {
    top: -2px;
    padding-bottom: 20px;
    font-size: 40px;
}

p {
    padding-left: 20px;
}
.mosts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    height: auto;
    margin-top: 80px;

    div {
        padding: 2px 2px 2px 2px;

        img {
            height: 200px;
            width: 150px;
            cursor: pointer;
        }

        span {
            display: block;
            width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0 auto;
            cursor: pointer;
        }
    }
}
</style>