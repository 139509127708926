import {request} from ".";
export const getID = data => {
    return request({
        method: "GET",
        url: "/getID/"+data,
    })
}
export const getMgetMovInfoCountVNew = ()=>{
    return request({
        method: "GET",
        url: "/getMovInfoCount",
    })
}
export const getMgetMovInfoOnly = (standard,id)=>{
    return request({
        method: "GET",
        url: "/getMovOnlyId/"+standard+"/"+id,
    })
}
export const getTvInfoCountTv = ()=>{
    return request({
        method: "GET",
        url: "/getTvInfoCountTv",
    })
}
export const getMvType = ()=>{
    return request({
        method: "GET",
        url: "/getMvType",
    })
}
export const getPlans = ()=>{
    return request({
        method: "GET",
        url: "/getPlans",
    })
}
export const getMovInfoIndex = ()=>{
    return request({
        method: "GET",
        url: "/getMovInfoIndex",
    })
}
export const getTvOnlyId = (id)=>{
    return request({
        method: "GET",
        url: "/getTvOnlyId/"+id,
    })
}
export const getMovInfo = ()=>{
    return request({
        method: "GET",
        url: "/getMovInfo/"
    })
}
export const getMovPage = (start,end)=>{
    return request({
        method: "GET",
        url: "/getMovPage/"+start+"/"+end
    })
}
export const getMovTvPage = (start,end)=>{
    return request({
        method: "GET",
        url: "/getMovTvPage/"+start+"/"+end
    })
}
export const getMovLike = (mvname,start,end)=>{
    return request({
        method: "GET",
        url: "/getMovLike/"+mvname+"/"+start+"/"+end
    })
}
export const getTvLike = (mvname,start,end)=>{
    return request({
        method: "GET",
        url: "/getTvLike/"+mvname+"/"+start+"/"+end
    })
}
export const getMovMvInfoMaxCount = ()=>{
    return request({
        method: "GET",
        url: "/getMovMvInfoMaxCount"
    })
}
export const getMovTvInfoMaxCount = ()=>{
    return request({
        method: "GET",
        url: "/getMovTvInfoMaxCount"
    })
}
export const getMovMvInfoLike = (type,start,end)=>{
    return request({
        method: "GET",
        url: "/getMovMvInfoLike/"+type+"/"+start+"/"+end
    })
}
export const getMovTvInfoLike = (plan,start,end)=>{
    return request({
        method: "GET",
        url: "/getMovTvInfoLike/"+plan+"/"+start+"/"+end
    })
}