<template>
   <div class="maxs">
      <div class="mid">
           <!--   <div class="mid_1">
                <div class="eveday">
                    <div class="eveday_box">
                        <div class="eveday_box_bor"></div>
                        <div class="info" v-for="(item, index) in data.MovCountVNewOnly" :key="index">
                            <div class="top">
                                <div>
                                    <div class="mvname">{{ item.mvname }}</div>
                                    <img class="image" :src="item.mvimage" />
                                    <div class="player" @click="player(item.id, 'mv')">立即播放</div>
                                </div>
                                <div class="mvinfo">
                                    <div>类型:{{ item.mvtype }}</div>
                                    <div>评分:{{ item.mvscore }}</div>
                                    <div>地区:{{ item.mvplan }}</div>
                                    <div>年份:{{ item.mvyear }}</div>
                                    <div>主演:{{ item.mvactor }}</div>
                                    <div>导演:{{ item.mvdirector }}</div>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div class="boot">
                                <h3 style="flex-shrink: 0;">简介:</h3>
                                <p>{{ str_replace(item.mvintroduction) }}</p>
                            </div>
                        </div>
                        <div>
                            <buttom class="next" @click="nextMov">next</buttom>
                            <div class="message">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mid_1_text">
                    <div class="ps">
                        <span class="mid_1_text_span1">最新电影</span>
                        <fun class="funBox" />
                    </div>
                    <div class="mid_1_p">
                        <div v-for="(item, index) in data.MovCountVNew" :key="index" style="padding-left:10px">
                            <div>
                                <div @click="player(item.id, 'mv')"><img :src="item.mvimage"></div>
                                <div style="width: 105px;
                                                                                                            white-space: nowrap;
                                                                                                                text-overflow: ellipsis;
                                                                                                                overflow: hidden;
                                                                                                                    ">{{
                                                                                                                        item.mvname }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mid_1_us" @mousemove="anime_(0)" @mouseleave="anime_(1)">
                        <div class="mid_1_us_A" v-if="showBox"></div>
                        <div @click="more('mv')">更多</div>
                    </div>
                </div>
            </div>
            <div class="mid_2">
                <div class="mid_2_box">
                    <div class="mid_2_box_menu">
                        <span>最新动漫</span>
                        <span>列出</span>
                    </div>
                    <div class="mid_2_box_shop">
                        <div class="m_ mid_2_box_shop_1" v-for="(item, index) in data.MovCountTv" :key="index">
                            <div class="imgs"><img @click="player(item.id, 'tv')" :src="item.tvimage" alt="">
                                <div class="time">{{ item.tvname }}</div>
                                <div class="title"></div>
                                <div class="msg_mid_2">最新</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mid_3">
                <div class="mid_3_title">
                    <span class="sp1">电影</span>
                    <span class="sp2">@类型</span>
                </div>
                <div class="mid_3_Box">
                    <div v-for="(item, index) in data.MvType" :key="index">
                        <div class="mid_3_Box_img"><img :src="item.image" alt=""  @click="classification(item.typeo)">
                            <div class="name">{{ item.typeo }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'mid_3_out': !colors.show, 'mid_3_out_': colors.show }"></div>
            <div class="mid_4">
                <div class="mid_4_title">动漫 &nbsp; &nbsp; &nbsp;<span>@地区 <span class="more" @click="more('tv')">更多</span></span></div>
                <div class="mid_4_Box">

                    <div class="mid_4_ mid_4_Box_1" v-for="(item, index) in data.Plans" :key="index" @click="plans(item.plan)"><img
                            :src="imagePlan(index)" alt="" />
                        <div><span>{{ item.plan }}</span></div>
                    </div>
                </div>
            </div> -->
            <div class="mid_6">
                <!-- <div class="mid_6_shop">关于我们</div> -->
                <div class="mid_6_text">
                    <p>只是一群电影动漫爱好者...</p>
                    <div class="Players">
                        <Players />
                    </div>
                </div>
            </div>
            <!-- <div class="end">
                <div :class="{ 'mid_3_out': !colors.show, 'mid_3_out_': colors.show }"></div>
            </div>
        </div>
        <div class="mores"> -->
        </div>
    </div>
</template>

<script>
require("../../assets/scss/mid.scss")
import anime from 'animejs'
import headCom from "@/components/head/headCom"
import fun from "@/components/fun/fun"
import { ref, watch, nextTick, onMounted, reactive } from 'vue';
import imagex0 from '@/assets/image/1.png'
import imagex1 from '@/assets/image/2.png'
import imagex2 from '@/assets/image/3.png'
import { getMgetMovInfoCountVNew, getMgetMovInfoOnly, getTvInfoCountTv, getMvType, getPlans } from "../../net/api"
import Players from '../players/index.vue'
import most from "@/components/most"
import { useRouter } from 'vue-router';
export default {
    components: { headCom, fun, Players, most },
    props: {
        colors: {
            type: Object,
            default: {}
        }
    },
    //props: ["colors"],
    setup(props) {
        let time = null;
        let timer = null;
        let timert = ref(10);
        let flag = ref(true);
        let showBox = ref(1)
        let data = reactive({
            MovCountVNew: {},
            MovCountVNewOnly: {},
            MovCountTv: {},
            MvType: {},
            Plans: {}
        })
        let mostsshow = ref(false)
        const router = new useRouter()
        function player(id, de) {
            router.push({ path: '/player', query: { id, de } })
        }
        function str_replace(mvintroduction) {
            return mvintroduction.replace(/[/&nbsp;,\s,&nb]/g, "")
        }
        function classification(type){
            router.push({ path: '/classification', query: { type } })
        }
        function anime_(value) {
            showBox.value = 1
            if (time != null) clearInterval(time)
            time = setTimeout(() => {
                if (!value) {
                    anime({
                        targets: '.mid_1_us_A',
                        translateX: 120,
                        duration: 1000
                    });
                } else {
                    anime({
                        targets: '.mid_1_us_A',
                        translateX: 260,
                        duration: 1000
                    });
                    setTimeout(() => {
                        showBox.value = !showBox.value
                    }, 500)
                }
            }, 200)
        }
        function more(type) {
            router.push({ path: '/most',query:{type}})
        }
        function plans(plan){
            router.push({ path: '/plans',query:{plan}})
        }
        function imagePlan(imageVal) {
            if (imageVal === 0) { return imagex0 }
            if (imageVal === 1) { return imagex1 }
            if (imageVal === 2) { return imagex2 }
            if (imageVal === 3) { return imagex3 }
        }
        function nextMov() {
            console.log(flag.value)
            if (!flag.value) {
                return false
            } else {
                setTimeout(() => {
                    getMgetMovInfoOnlyfun()
                }, 2000)

            }
            flag.value = false;
            timer = setInterval(() => {
                timert.value = timert.value - 1;
                if (timert.value <= 0) {
                    flag.value = true;
                    clearInterval(timer)
                    timert.value = 10
                    anime({
                        targets: '.next',
                        translateX: 0,
                        duration: 1000
                    });
                } else {
                    anime({
                        targets: '.next',
                        translateX: 2000,
                        duration: 1000
                    });
                }
            }, 1000)

        }
        async function getMgetMovInfoOnlyfun() {
            await getMgetMovInfoOnly(0, 0).then((res) => {
                data.MovCountVNewOnly = res.data;
                //console.log(data.MovCountVNewOnly)
            });
        }
        onMounted(() => {
            document.documentElement.scrollTop = 0;
            async function getMgetMovInfoCountVNewfun() {
                await getMgetMovInfoCountVNew().then((res) => {
                    data.MovCountVNew = res.data;
                   // console.log(data.MovCountVNew)
                });
            }
            async function getMgetMovInfoCountTvfun() {
                await getTvInfoCountTv().then((res) => {
                    data.MovCountTv = res.data;
                   // console.log(data.MovCountTv)
                });
            }
            async function getMvTypefun() {
                await getMvType().then((res) => {
                    data.MvType = res.data;
                  //  console.log(data.MvType)
                });
            }
            async function getPlansfun() {
                await getPlans().then((res) => {
                    data.Plans = res.data;
                   // console.log(data.Plans)
                });
            }
            getMgetMovInfoCountVNewfun()
            getMgetMovInfoOnlyfun()
            getMgetMovInfoCountTvfun()
            getMvTypefun()
            getPlansfun()
            // let scrollDiv = document.getElementsByClassName("mores")[0];
            // scrollDiv.addEventListener('scroll', function () {
            //     let windowHeight = document.documentElement.clientHeight - 10
            //     let SH = parseInt(scrollDiv.scrollTop + windowHeight);
            //     let H = parseInt(scrollDiv.scrollHeight);
            //     if (SH >= H) {
            //         bottoms.value =1
            //     }else{
            //         bottoms.value = 0
            //     }
            // })
        })
        watch(() => props.colors.urlimage, (news, old) => {
            nextTick(() => {
                document.getElementsByClassName("mid_3_out")[0].style.backgroundImage = "url(" + news + ")"
                document.getElementsByClassName("mid_3_out")[1].style.backgroundImage = "url(" + news + ")"
            })
        }, { immediate: true })
        return { showBox, anime_, props, data, nextMov, imagePlan, player, str_replace, more, mostsshow,classification,plans}
    }
}
</script>

<style lang="less">
.maxs {
    height: auto;
    width: 100%;
}

.mid {
    height: auto;
    width: 100%;

    .mid_1 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        margin-top: 60px;
        justify-content: space-between;
        flex-wrap: wrap;

        .eveday {
            background-image: url('@/assets/image/index.png');
            width: 60%;
            overflow: hidden;

            .eveday_box {
                width: 100%;
                background-color: rgba(255, 255, 255, 0.5);
                height: 100%;
                position: relative;

                .next {
                    position: absolute;
                    top: 70%;
                    right: 60px;
                    z-index: 9999;
                    width: 90px;
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    border: 1px solid white;
                    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                    font-size: 18px;
                    color: white;
                    cursor: pointer;
                }

                .next:hover {
                    background-color: black;
                    box-shadow: red;
                    box-shadow: 10px 10px 5px #888888;
                }

                .message {
                    position: absolute;
                    top: 30%;
                    right: 120px;
                    z-index: 9999;
                    width: 90px;
                    height: 60px;

                }

                .eveday_box_bor {
                    background-color: rgba(121, 57, 81, 0.7);
                    height: 100%;
                    width: 100%;
                    transform: rotate(19deg);
                    position: absolute;
                    z-index: 9999;

                }

                .info {
                    position: relative;
                    z-index: 9999;
                    display: flex;
                    color: white;
                    flex-direction: column;
                    align-items: self-start;
                    width: 80%;
                    margin: 0 auto;
                    padding-top: 15%;


                    .top {
                        line-height: unset !important;
                        display: flex;
                        font-size: 15px;
                        display: flex;
                        text-align: center;
                        justify-content: center;
                        align-items: center;

                        .mvname {
                            font-size: 18px;
                        }

                        .image {
                            height: 180px;
                            width: 120px;
                            background-color: white;
                            margin-bottom: 20px;
                            margin-top: 15px;
                        }

                        .player {
                            border: 2px solid white;
                            height: 30px;
                            line-height: 30px;
                            cursor: pointer;
                        }

                        .player:hover {
                            background-color: black;
                        }

                        .movj {
                            background-color: aquamarine;
                            height: 200px;
                        }

                        .mvinfo {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            padding-top: 5px;
                            height: 210px;
                            padding-left: 20px;
                            padding-top: 10px;
                            text-align: start;

                            div {
                                width: 300px;
                                /* 强制不换行 */
                                white-space: nowrap;
                                /* 文字用省略号代替超出的部分 */
                                text-overflow: ellipsis;
                                /* 匀速溢出内容，隐藏 */
                                overflow: hidden;
                            }
                        }
                    }

                    .boot {
                        display: flex;
                        justify-content: center;
                        padding-left: 40px;
                        padding-top: 50px;

                        p {
                            width: 90%;
                            word-wrap: break-word;
                            word-break: break-all;
                            line-height: 25px;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }

        img {
            margin-top: 90px;
            flex: 1;
            margin-left: 0;
        }

        .mid_1_text {
            padding-left: 110px;
            flex: 1;
            display: flex;
            flex-direction: column;

            .ps {
                position: relative;
            }

            .mid_1_text_span1 {
                writing-mode: tb-rl;
                padding-left: 19px;
                font-size: 40px;
                float: left;
                margin-top: 100px;
                display: block;
            }


            .mid_1_p {
                width: 50%;
                display: flex;
                text-align: center;
                flex-wrap: wrap;

                img {
                    height: 150px;
                    cursor: pointer;
                }
            }

            .mid_1_us {
                margin-top: 60px;
                height: 50px;
                width: 120px;
                border: 1px solid #793951;
                text-align: center;
                line-height: 50px;
                color: #793951;
                cursor: pointer;
                position: relative;
                overflow: hidden;

                .mid_1_us_A {
                    position: absolute;
                    left: -120px;
                    right: 120px;
                    top: 0;
                    bottom: 0;
                    background-color: #793951;
                    opacity: 0.2;
                }
            }

        }
    }

    .mid_2 {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 90px;

        .mid_2_box {
            background-color: #f8eff2;
            width: 90%;

            .mid_2_box_menu {
                padding-bottom: 20px;
                padding-top: 40px;
                margin-left: -60px;
                font-size: 25px;
            }

            .mid_2_box_menu :last-child {
                padding-left: 40px;
                font-size: 20px;
            }

            .mid_2_box_shop {
                display: flex;
                flex-direction: row;
                cursor: pointer;
                padding-bottom: 120px;
                padding-top: 80px;
                margin-left: -90px;

                .imgs {
                    position: relative;
                    margin: 0 auto;
                }

                img {
                    width: 90%;
                    border-radius: 6px;
                    margin-top: 10px;
                }

                .m_ {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    width: 100%;
                    text-align: center;
                    border-radius: 5px;
                    margin-left: 20px;
                    transition: all .5s;

                    .time {
                        padding-top: 15px;
                        font-size: 12px;
                        padding-left: 10px;
                        text-align: start;
                    }

                    .title {
                        padding-top: 15px;
                        padding-left: 10px;
                        text-align: start;
                    }

                    .msg_mid_2 {
                        writing-mode: tb-rl;
                        position: absolute;
                        top: 120px;
                        height: 60px;
                        width: 30px;
                        line-height: 30px;
                        color: white;
                        text-align: start;
                        background-color: #793951;
                        padding-top: 15px;
                    }
                }

                .mid_2_box_shop_1:hover {
                    background-color: white;
                    margin-top: -10px;
                }

                .mid_2_box_shop_2:hover {
                    background-color: white;
                    margin-top: -10px;
                }

                .mid_2_box_shop_3:hover {
                    background-color: white;
                    margin-top: -10px;
                }

                .mid_2_box_shop_4:hover {
                    background-color: white;
                    margin-top: -10px;
                }
            }
        }
    }

    .mid_3_out {
        width: 100%;
        z-index: -5000;
        background-position: center;
        background-size: cover;
        transition: all 2s;
        background-attachment: fixed;
        height: 700px;
    }

    .mid_3_out_ {
        background-color: white;
        width: 100%;
        z-index: -5000;
        background-position: center;
        background-size: cover;
        transition: all 2s;
        opacity: 0;
        background-attachment: fixed;
        height: 700px;
    }

    .mid_3 {
        display: flex;
        flex-direction: column;
        width: 97%;
        margin-top: 90px;
        padding-bottom: 120px;

        .name {
            position: absolute;
            color: white;
            font-size: 19px;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }

        img {
            height: 150px;
            width: 150px;
            background-position: center;
            transition: all 0.6s;
            cursor: pointer;
            opacity: 0.9;
        }

        img:hover {
            transform: scale(1.1);
            transition: all 0.3s;
            opacity: 0.8;
            animation-fill-mode: forwards;
        }

        .mid_3_Box {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        .mid_3_Box_img {
            margin-left: 3%;
            margin-top: 20px;
            height: 145px;
            overflow: hidden;
            border: 10px solid white;
            position: relative;

        }

        .mid_3_title {
            span {
                font-size: 20px;
                padding-left: 60px;
            }

            .sp1 {
                font-size: 30px;
            }

            .sp2 {
                color: #793951;
            }
        }
    }

    .mid_4 {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        height: auto;
        width: 100%;

        .mid_4_title {

            margin-left: 200px;
            font-size: 40px;
            margin-top: 30px;

            span {
                font-size: 20px;
                color: #793951;
            }
            .more{
                margin-left: 60px;
                cursor: pointer;
            }
        }

        .mid_4_Box {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: end;
            margin-right: 20px;
            padding-top: 120px;

            img {
                height: 420px;
                transition: all 0.6s;
                cursor: pointer;
                opacity: 0.9;
            }

            img:hover {
                transform: scale(1.1);
                transition: all 0.3s;
                opacity: 0.8;
                animation-fill-mode: forwards;
            }

            @keyframes first {
                from {
                    filter: brightness(0.5);
                }

                to {
                    filter: brightness(1);
                }
            }

            @keyframes second {
                from {
                    filter: brightness(1);
                }

                to {
                    filter: brightness(0.5);
                }
            }

            .mid_4_ {
                width: auto;
                height: 420px;
                text-align: center;
                overflow: hidden;
                border: 10px solid white;
                margin-left: 1px;
                border-radius: 10px;
                position: relative;

                div {
                    writing-mode: tb-rl;
                    color: white;
                    font-size: 25px;
                    cursor: pointer;
                    z-index: -1000;

                    span {
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            .mid_4_Box_2 {
                margin-top: 40px;
            }

            .mid_4_Box_4 {
                margin-top: 40px;
            }
        }

    }

    .mid_5 {
        width: 90%;
        background-color: #f8eff2;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-self: flex-start;
        border-radius: 5px;
        margin-top: 90px;

        .mid_5_title {
            align-self: flex-start;
            width: 500px;
            display: flex;
            padding-top: 40px;

            .sp1 {
                display: block;
                font-size: 40px;
                padding-left: 90px;
            }

            .sp2 {
                display: block;
                font-size: 15px;
                padding-left: 40px;
                padding-top: 10px;
            }
        }

        .mid_5_Box {
            display: flex;
            flex-direction: column;
            padding-top: 60px;
            margin-right: -90px;
            margin-bottom: 90px;

            img {
                height: 350px;
            }

            img:hover {
                transform: scale(1.1);
                transition: all 0.3s;
                opacity: 0.8;
                animation-fill-mode: forwards;
            }

            @keyframes first {
                from {
                    filter: brightness(0.5);
                }

                to {
                    filter: brightness(1);
                }
            }

            @keyframes second {
                from {
                    filter: brightness(1);
                }

                to {
                    filter: brightness(0.5);
                }
            }

            .text {
                padding-top: 20px;
                width: 265px;
                text-align: center;
                margin-left: 30px;
            }

            .mid_5_Box_up {
                display: flex;
                flex-direction: row;
                text-align: center;
                justify-content: end;
                margin-bottom: 40px;

                .mid_5_Box_up_img {
                    width: 260px;
                    overflow: hidden;
                    height: 360px;
                    text-align: center;
                    margin-left: 30px;
                    height: 340px;
                }
            }

            .mid_5_Box_down {
                display: flex;
                flex-direction: row;
                text-align: center;
                justify-content: end;

                .mid_5_Box_up_img {
                    width: 260px;
                    overflow: hidden;
                    height: 360px;
                    text-align: center;
                    margin-left: 30px;
                    height: 340px;
                }
            }
        }
    }

    .mid_6 {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
       // margin-top: 90px;
        padding-bottom: 90px;
        margin: 10% auto;

        .mid_6_shop {
            writing-mode: tb-rl;
            font-size: 40px;
            margin-left: 40px;
            padding-top: 40px;
        }

        .mid_6_text {
           // width: 500px;
            line-height: 30px;
           // padding-left: 60px;

            p {
                padding-left: 30px;
                font-weight: 200;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
        }

        .mid_6_but {
            margin-top: 30px;
            cursor: pointer;
        }

        .mid_6_Box {
           // padding-right: 20px;
        }

        .mid_6_Box_up {}

        .mid_6_Box_down {
            display: flex;

            div {
                margin-top: 15px;
            }

            .mid_6_Box_down_2 {
               // padding-left: 15px;
            }
        }
    }

    .end {
        color: #793951;
        font-size: 12px;
        width: 100%;
        text-align: start;
        position: relative;

        span {
            padding-left: 30px;
            display: block;
            position: absolute;
            bottom: 10px;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        }
    }
}

.mores {
    background-color: white;
    position: fixed;
    top: 5px;
    height: 1px;
    width: 110%;
    z-index: 9999999999999999;
    overflow-y: scroll;
    right: -20px;
    left: 0;
    height: auto;
}
</style>