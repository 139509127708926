import axios from "axios";
export function request(config){
    const ax = axios.create({
        baseURL:"http://localhost:9000",
        timeout: 5000
    })
    ax.interceptors.request.use(config=>{
        return config;
    })
    ax.interceptors.response.use(response=>{
        if(response.status===200){
            return response;
        }
        if(response.status===500){
                return false;
        }
    })
    return ax(config)
}
