<template>
    <div class="video_box">
        <div>
            <vue3VideoPlay class="video" v-bind="options" poster='https://go.dreamwq.com/videos/ironMan.jpg' @play="onPlay"
                @pause="onPause" @timeupdate="onTimeupdate" @canplay="onCanplay" />
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, watch, onUnmounted } from "vue"
import { onBeforeRouteLeave } from "vue-router"
import axios from "axios"
export default {
    props: ["src"],
    setup(props) {
        const options = reactive({
            width: '80%', //播放器高度s
            height: '550px', //播放器高度
            color: "#409eff", //主题色
            title: '', //视频名称
            src: "", //视频源
            type: 'm3u8', //视频类型
            muted: false, //静音
            webFullScreen: false,
            speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
            autoPlay: false, //自动播放
            loop: false, //循环播放
            mirror: false, //镜像画面
            ligthOff: false,  //关灯模式
            volume: 0.3, //默认音量大小
            control: true, //是否显示控制器

        })
        const onPlay = (ev) => {
           // ev.play()
        }
        const onPause = (ev) => {
          //  ev.pause()
        }

        const onTimeupdate = (ev) => {
            // console.log(ev, '时间更新')
        }
        const onCanplay = (ev) => {
            // console.log(ev, '可以播放')
        }
        let opts = {
            "Accept": "text/ html, application/xhtml+xml,application/xml; q = 0.9, image / avif, image / webp,*/*;q=0.8",
            "Accept- Encoding":"gzip, deflate, br",
            "Accept - Language":"zh - CN, zh; q = 0.8, zh - TW; q = 0.7, zh - HK; q = 0.5, en - US; q = 0.3, en; q = 0.2",
            "Connection":"keep - alive",
            "Cookie":"__51uvsct__Jt6a3mRjUIT4SdBl = 2; __51vcke__Jt6a3mRjUIT4SdBl = 9ad416c2 - cf99 - 55fd - bfda - 67afbd5ed41a; __51vuft__Jt6a3mRjUIT4SdBl = 1676394046330",
            "Host":"ukzy.ukubf4.com",
            "Sec - Fetch - Dest":"document",
            "Sec - Fetch - Mode":"navigate",
            "Sec - Fetch - Site":"cross - site",
            "Upgrade - Insecure - Requests":" 1",
            "User - Agent":"Mozilla / 5.0(Windows NT 10.0; Win64; x64; rv: 109.0) Gecko / 20100101 Firefox / 110.0"
        }
    //    function encode(str) {
    //     var encode = encodeURI(str);
    //   return (
    //     "data:video/mp4;base64," + btoa(encode)
    //   )
    // }
        watch(() => props.src, (news, old) => {
            console.log(news)
           // let url = news.substring(news.indexOf(".com")+4);
           // axios.get("/video"+url).then(res=>{
             //  url = res.data.substring(res.data.indexOf("/"));
               // console.log(url)
                // axios.get("http://localhost:8888?url="+news).then(res=>{})
                options.src = news
          //  })
            //jsonp(news, opts).then(e => {})
            
            let video = document.getElementsByClassName("video")[0];
            video.play
           // console.log(news + "6666666666666666666")

    })

onMounted(() => {
    // let video_box = document.getElementsByClassName("video_box")[0];
    // video_box.remove()
})
onBeforeRouteLeave((to, from) => {
    console.log(+ "6666666666666666666")
    let video_box = document.getElementsByClassName("video_box")[0];
    video_box.remove()

})
return { options, onPlay, onPause, onTimeupdate, onCanplay }
    }
}

</script>

<style lang="less" scoped>
.video_box {
    color: white;
    padding-top: 10px;
    width: 100%;

 div{
    margin: 0 auto;
 }
}
</style>