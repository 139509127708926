<template>
    <div>
        <div>
            <h2 class="h2s">{{ $route.query.plan }}动漫({{ maxLength[0] }})</h2>
            <h3 class="h2s">你喜欢什么动漫</h3>
        </div>


        <div class="mosts">
            <div v-for="(item, index) in data.mov" :key="index">
                <img :src="item.tvimage" @error="" alt="" @click="player(item.id, 'tv')">
                <span>{{ item.tvname }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, ref, watch, nextTick } from 'vue';
import { getMovTvInfoLike } from "@/net/api"
import { useRouter, useRoute } from 'vue-router';
export default {
    components: {},
    setup(props, context) {

        let data = reactive({
            mov: [],
        })
        const maxLength = ref(0);
        let start = ref(1)
        let end = ref(36)
        let timer = ref("")
        const router = new useRouter()
        const route = new useRoute()
        function player(id, de) {
            router.push({ path: '/player', query: { id, de } })
            document.documentElement.style.overflow = 'auto'
        }
        async function getMovTvInfoLikefun() {
            await getMovTvInfoLike(route.query.plan, start.value, end.value).then(res => {
                maxLength.value = res.data.length
              // console.log(maxLength.value[0])
                    if (data.mov.length !== 0) {
                        res.data.data.forEach(function (item) {
                            data.mov.push(item);
                        })
                    } else { data.mov = res.data.data; }
            })
        }

        function scrollFn() {
            let scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            let windowHeight =
                document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight =
                document.documentElement.scrollHeight || document.body.scrollHeight;
            if (timer.value) {
                clearInterval(timer.value);
            }
            timer.value = setTimeout(() => {
                if (scrollTop + windowHeight >= scrollHeight - 200) {
                    //触底
                    start.value = start.value + 36; end.value = end.value + 36;
                    if (end.value <= maxLength.value[0]) {
                        getMovTvInfoLikefun(end.value)
                    }

                } else if (scrollTop + windowHeight <= scrollHeight - 200) {

                }
            }, 500);
        }
        onMounted(() => {
            nextTick(() => {
                getMovTvInfoLikefun()
            })
            window.addEventListener("scroll", scrollFn);

        })
        return { data, player, maxLength }
    }
}
</script>

<style lang="less" scoped>
.h2s {
    text-align: center;
    margin-top: 50px;
}

.mosts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    height: auto;
    margin-top: 40px;

    div {
        padding: 2px 2px 2px 2px;

        img {
            height: 200px;
            width: 150px;
            cursor: pointer;
        }

        span {
            display: block;
            width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0 auto;
            cursor: pointer;
        }
    }
}
</style>