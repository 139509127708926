export default {
    namespaced: true, //开启命名空间
    state: {
        name: '张三',
        personList: []
    },
    mutations: {
        ADD_PERSON(state, value) {
            state.personList.unShift(value) //把数据添加到数组的首位
        }
    },
    getters:{
        show(state){
            return state.name + state.personList
        }
    },
}