export default {
    namespaced: true, //开启命名空间
    state: {
        mvurl:""
    },
    mutations: {
        ADD_URL(state, value) {
            console.log(value)
            state.mvurl = value
        }
    },
    getters: {
        show(state) {
            return state.mvurl
        }
    },
}