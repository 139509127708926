<template>
    <div>
        <div :class="{ 'head_min_': !colors.show, 'head_min_2': colors.show }">
            <div class="mvnameh"  ><span @click="player(colors.id,'mv')">{{colors.mvname}}</span></div>
        </div>
</div>
</template>

<script>
import { watch } from 'vue';
import { nextTick } from 'vue';
import { useRouter } from 'vue-router';
export default {
    props: ["colors", "position"],
    setup(props) {
        const router = new useRouter()
        function player(id, de) {
            router.push({ path: '/player', query: { id, de } })
        }
        watch(() => props.colors.urlimage, (news, old) => {
            nextTick(() => {
                document.getElementsByClassName("head_min_")[0].style.backgroundImage = "url(" + news + ")"
            })
        }, { immediate: true })
        return {props,player};
    }
}
</script>

<style lang="less">
.head_min_ {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 99;
    background-position: center;
    background-size: cover;
    transition: all 2s;
    background-attachment: fixed;
    background-color: antiquewhit;
}

.head_min_2 {
    background-color: white;
    width: 100%;
    position: absolute;
    top: 0;
    background-position: center;
    background-size: cover;
    transition: all 2s;
    opacity: 0;
    background-attachment: fixed;
}

    .mvnameh {
        color: white;
        padding-top: 200px;
        padding-left: 100px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 200;
        font-size: 40px;
       
        span{
             cursor: pointer;
        }
    }
</style>