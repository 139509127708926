<template>
    <!-- <v_head /> -->
        <router-view :colors="colors"></router-view>
    <!-- <foot /> -->
</template>

<script>
import v_head from "@/components/head/"
import foot from "@/components/foot/"
import bus from '@/tool/bus.js'
import { reactive, onMounted, watch } from "vue"
export default {
    name: "index",
    components: { v_head, foot },
    setup() {
        let colors = reactive({
            color: 0,
            show: 0,
            height: "",
            urlimage: "",
            mvname: "",
            scrollTop: 0,
        })
        function getColors(){
            bus.on("response", (response) => {
                colors.urlimage = response.urlimage
                colors.mvname = response.mvname
                colors.color = response.color
                colors.show = response.show
            })
            console.log(colors)
        }
        onMounted(() => {
            getColors()
        })


        return { colors }
    },

}
</script>
<style lang="less" scoped>
</style>