import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/index.less"
import element from 'element-plus';
import 'element-plus/dist/index.css'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css


createApp(App).use(store).use(router).use(element).use(vue3videoPlay).mount("#app");
