<template>
    <div class="footbox">
        <div class="left_box_box">
            <div>简体中文</div>
            <div>本站内容均转载于公开的网络或由用户分享，仅用于网络测试交流，严禁用于商业用途，本站并不提供资源存储。</div>
            <div>Copyright © 2019-2022 柒冉 | 如不慎侵权您的权益，请联系我们，我们会第一时间处理！</div>
        </div>
        <div class="right_box_box">
            <div>Email</div>
            <div>1670844920@qq.com</div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.footbox{
    height: 300px;
    width: 90%;
    background-color: #793951;
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    margin-top: 60px;
    .left_box_box{
        padding-left: 70px;
        div{padding-top: 10px;}
    }
    .right_box_box{
        padding-right:70px;
        div{padding-top: 10px;}
    }
}
</style>