<template>
  <div>
    <index v-if="isRouterAlive"/>
  </div>
</template>
<script>
import index from "@/components/body"
export default {
  components: { index },
  provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isCollapse: true,
      isRouterAlive: true,
    };
  },
  methods: {
    reload () {
      this.isRouterAlive = false;            //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true;         //再打开
      })
    }
  },
  
}
</script>
<style lang="less">
</style>
