<template>
    <div>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple-dark">
                    <div v-if="$route.query.de === 'mv'" class="details_boxs" v-for="(item, index) in data.CountVNewOnly"
                        :key="index">
                        <div class="details_left">
                            <div><img :src="item.mvimage" /><img :src="item.tvimage" /></div>
                            <div class="details_infos">
                                <div>{{ item.mvname }}</div>
                                <div>类型:{{ item.mvtype }}</div>
                                <div>评分:{{ item.mvscore }}</div>
                                <div>地区:{{ item.mvplan }}</div>
                                <div>年份:{{ item.mvyear }}</div>
                                <div>主演:{{ item.mvactor }}</div>
                                <div>导演:{{ item.mvdirector }}</div>
                                <br><br><br><br>
                                <div>简介:{{ str_replace(item.mvintroduction) }}</div>
                            </div>
                        </div>
                        <div class="details_right">
                            <div class="player">
                                <div @click="player(item.mvurl)">播放</div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="details_boxs" v-for="(item, index) in data.CountVNewOnly">
                        <div class="details_left">
                            <div><img :src="item.tvimage" /></div>
                            <div class="details_infos">
                                <div>{{ item.tvname }}</div>
                                <div>类型:{{ item.tvtype }}</div>
                                <div>评分:{{ item.tvscore }}</div>
                                <div>地区:{{ item.tvplan }}</div>
                                <div>年份:{{ item.tvyear }}</div>
                                <div>主演:{{ item.tvactor }}</div>
                                <div>导演:{{ item.tvdirector }}</div>
                                <br><br><br><br>
                                <div>简介:{{ str_replace(item.tvintroduction) }}</div>
                                <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                                <div class="tvurl">
                                    <div @click="player(item)" v-for="(item, index) in gettvurl(item.tvurl)" :key="index">
                                        第{{ index + 1 }}集</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <videos :src="src"/>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { nextTick, onMounted, reactive,ref } from 'vue';
import { useRoute } from 'vue-router';
import { getMgetMovInfoOnly, getTvOnlyId } from "../../net/api";
import { mapState, mapMutations } from "vuex";
import videos from "./video.vue"
export default {
    components: {videos},
    setup(props, context) {

        let data = reactive({
            CountVNewOnly: {},
        })
        let tvurl = reactive({
            data: {},
        })
        let src = ref("")
        function gettvurl(tvurl) {
            const strArr = tvurl.split(',')
            return strArr
        }
        function str_replace(mvintroduction) {
            return mvintroduction.replace(/[/&nbsp;,\s,&nb]/g, "")
        }
        function player(srcs) {
            src.value = srcs;
        }
        const route = new useRoute()
        async function getMgetMovInfoOnlyfun(id) {
            await getMgetMovInfoOnly(1, id).then((res) => {
                data.CountVNewOnly = res.data;
                console.log(data.CountVNewOnly)
            });
        }
        async function getTvOnlyIdfun(id) {
            await getTvOnlyId(id).then((res) => {
                data.CountVNewOnly = res.data;
                console.log(data.CountVNewOnly)
            });
        }
        onMounted(() => {
            nextTick(() => {
                if (route.query) {
                    if (route.query.de === "mv") {
                        data.CountVNewOnly = getMgetMovInfoOnlyfun(route.query.id)
                    } else {
                        data.CountVNewOnly = getTvOnlyIdfun(route.query.id)
                    }
                }
            })
        })
        return { data, player, str_replace, ...mapMutations('getUrl', ["ADD_URL"]), gettvurl, src }
    }
}
</script>

<style lang="less" scoped>
.details_boxs {
    display: flex;
    width: 70%;
    justify-content: space-between;
    margin: 0 auto;
    height: auto;
    margin-top: 40px;
    padding-bottom: 60px;

    .details_left {
        display: flex;
        justify-content: center;
        flex-direction: row;

        img {
            height: 180px;
        }

        .details_infos {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding-left: 10px;

            .tvurl {
                display: flex;
                padding: 10px 10px 10px 10px;
                justify-content: start;
                width: 90%;
                color: white;
                flex-wrap: wrap;
                height: 150px;
                overflow-y: auto;


                div {
                    background-color: black;
                    width: 60px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    cursor: pointer;
                    margin-left: 10px;
                }
            }
        }
    }

    .details_right {
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .player {
        width: 120px;
        height: 80px;
        background-color: black;
        color: white;
        text-align: center;
        line-height: 80px;
        cursor: pointer;
    }
}
</style>