import { createRouter, createWebHistory,createWebHashHistory } from "vue-router";
import Main from "@/components/mid/index";
import Player from "@/components/details/index";
import Search from "@/components/srch/index";
import most from "@/components/most/index";
import classification from "@/components/classification/index";
import plans from "@/components/plans/index";
const routes = [
  {path:'/',redirect:"/index"},
  {path:'/index',component:Main},
  {path:'/player',component:Player},
  {path:'/search',component:Search},
  {path:'/most',component:most},
  {path:'/classification',component:classification},
  {path:'/plans',component:plans},

  
];

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
